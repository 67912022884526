<template>
  <div class="hello">
    <img alt="Vue logo" src="../assets/logo-clic.png" height="300rem">
    <h3>CDI - Compliance Data Integration</h3>
    <p>
      <a href="https://cdi.clictecnologia.com.br/suporte" target="_blank" rel="noopener"> Clic Tecnologia</a>.
    </p>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/common/scss/colors.scss";

.hello {
  text-align: center;
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: $cor-principal;
}
</style>
