<template>
  <div class="layout">
    <span class="layout__titulo">{{ titulo }}   <v-icon v-show="showHelp()" variant="link" class="expanded" scale="1.2" name="question-circle" title="Clique para abrir a ajuda"/></span>
    {{link_help}}
    <slot />
  </div>
</template>

<script>
import { getUserObj, getEmpresa } from '@/common/localStorage/localStorage'
//Vuex
import { mapActions } from "vuex";

export default {
  name: 'LayoutTelas',
  props: {
    titulo: {
      type: String,
      required: true,
    },
    link_help: {
      type: String,
      required: false
    }
  },
  sockets: {
    CONECTAR_CDI: async function (data) {
      this.autenticar()
    }
  },
  created () {
    this.autenticar()
  },
  methods: {
    async autenticar(){
      // this.$socket.emit('connected', {token:this.$route.params.auth})
      this.$socket.emit('connected', {token:(getUserObj()).token})
    },
    showHelp() {
      return this.$route.meta.link_help && this.$route.meta.link_help != '';
    }
  },
  
}
</script>

<style lang="scss" scoped>
@import '@/common/scss/colors.scss';
@import "@/common/scss/breakpoints.scss";

.layout {
  margin-top: 4.5rem;
  position: relative;
  height: 100%;
  width: 90%;
  padding: 2.5em 1em 1em 1em;
  // border-radius: 0.4em;
  box-shadow: 2px 4px 15px darken($cor-fundo-escura, 15%);
  background-color: $cor-fundo-branca;

  &__titulo {
    position: absolute;
    top: -0.5em;
    left: 0.5em;
    padding: 0.3em 0.4em;
    color: $cor-fonte-clara;
    background-color: $cor-principal;
    box-shadow: 2px 4px 8px $cor-fundo-escura;
    // border-top-left-radius: 0.4em;
    // border-bottom-right-radius: 0.4em;
    border-radius: 0.2em;
    font-size: 1.1em;
  }
}
</style>
