<template>
  <nav class="navbar">
    <NavbarUser class="navbar__usuario" />
  </nav>
</template>

<script>
import NavbarUser from './NavbarUser.vue'

export default {
  name: "Navbar",
  components: {
    NavbarUser,
  },
};
</script>


<style lang="scss" scoped>
@import "@/common/scss/colors.scss";

.versao {
  color: $cor-fonte-clara;
  margin: 0.5em;
}

.navbar {
  z-index: 100;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  width: 100%;
  height: 3.9rem;
  background-color: $cor-principal;
}

</style>