//Servidor desenvolvimento
// const URL_BASE = 'localhost';

// export const API_BASE = `http://${URL_BASE}:6001`
// export const API_AUTENTICACAO = `http://${URL_BASE}:6001/autenticacao`
// export const API_GATEWAY = `http://${URL_BASE}:6002/gateway`
// export const API_EBS = `http://${URL_BASE}:6005`
// export const API_SOCKET = `http://${URL_BASE}:6003`
export const API_BASE = `http://${process.env.VUE_APP_URL_BASE}:6001`
export const API_AUTENTICACAO = `http://${process.env.VUE_APP_URL_BASE}:6001/autenticacao`
export const API_GATEWAY = `http://${process.env.VUE_APP_URL_BASE}:6002/gateway`
export const API_EBS = `http://${process.env.VUE_APP_URL_BASE}:6005`
export const API_SOCKET = `http://${process.env.VUE_APP_URL_BASE}:6003`