<template>
  <main class="layout__corpo">
    <slot />
  </main>
</template>

<script>
// import { getUserObj, getEmpresa } from '@/common/localStorage/localStorage'
// import { getTela, setTela } from '@/common/sessionStorage/sessionStorage'

export default {
  name: 'LayoutBasico',
  created() {
    // if(getUserObj() && getUserObj() != ""){
    //   let obj = getUserObj()
    //   obj.empresa = getEmpresa()
    //   this.$socket.emit('connected', obj)
    // }
  },
  sockets: {
    CONECTAR_CDI: async function (data) {
      // console.log('aaaaaaaa')
      // this.autenticar()
    }
  },
}
</script>

<style lang="scss" scoped>
.layout__corpo {
  height: auto;
  width: inherit;
}
</style>
