import {zerosEsquerda} from './StringHelper'
import * as moment from 'moment'

// Passar um Date
export const retornaDataYYYYMMDD = data => `${data.getFullYear()}-${zerosEsquerda(data.getMonth()+1, 2)}-${zerosEsquerda(data.getDate(), 2)}`

// Passar um Date
export const retornaDataFormatadaDDMMYYYY = data => `${zerosEsquerda(data.getDate(), 2)}/${zerosEsquerda(data.getMonth()+1, 2)}/${data.getFullYear()}`
export const retornaDataFormatadaYYYYMMDDHHmmSS = data => `${data.getFullYear()}${zerosEsquerda(data.getMonth()+1, 2)}${zerosEsquerda(data.getDate(), 2)}${zerosEsquerda(data.getHours(), 2)}${zerosEsquerda(data.getMinutes(), 2)}${zerosEsquerda(data.getSeconds(), 2)}`

export const paraDate = (dia, mes, ano) => new Date(ano, mes - 1, dia)

export const retornaStringParaYYYYMMDD = dataString => dataString.substring(0, 10)

export const retornaISOParaStringComMascara = (string, mascara) => {
	try {
		if (!string) {
			return ''
		}

		const data = moment(string)
		return data.utc(-3).format(mascara)
	} catch {
		return ''
	}
}

export const getMoment = () => {
	return moment()
}

export const dataEhMaiorIgual = (data1, data2) => {
	const dt1 = new Date(data1)
	const dt2 = new Date(data2)

	return dt1 >= dt2
}
export const dataEhMenorIgual = (data1, data2) => {
	const dt1 = new Date(data1)
	const dt2 = new Date(data2)

	return dt1 <= dt2
}
export const paraDateISO = (dataHoraISO) => {
	return moment(dataHoraISO)
}
export const horaEhMaiorIgual = (dataHora1, dataHora2) => {
	const dataHora1Format = this.paraDate(dataHora1)
	const dataHora2Format = this.paraDate(dataHora2)

	const horaMaior = dataHora1Format.hour() > dataHora2Format.hour()
	if (horaMaior) {
		return true
	}

	const horaIgual = dataHora1Format.hour() === dataHora2Format.hour()
	if (horaIgual) {
		return dataHora1Format.minutes() >= dataHora2Format.minutes()
	}

	return false
}
export const horaEhMenorIgual = (dataHora1, dataHora2) => {
	const dataHora1Format = this.paraDate(dataHora1)
	const dataHora2Format = this.paraDate(dataHora2)

	const horaMenor = dataHora1Format.hour() < dataHora2Format.hour()
	if (horaMenor) {
		return true
	}

	const horaIgual = dataHora1Format.hour() === dataHora2Format.hour()
	if (horaIgual) {
		return dataHora1Format.minutes() <= dataHora2Format.minutes()
	}

	return false
}

export const diferencaEmHoras = (dataHora1, dataHora2) => {
	const dataHora1Format = this.paraDate(dataHora1)
	const dataHora2Format = this.paraDate(dataHora2)

	return dataHora1Format.diff(dataHora2Format, 'hours')
}

export const atualDateYYYYMMDD = (separador) => {
	let dataAtual = new Date(Date.now());
	let dataAtualFormatada = (dataAtual.getFullYear() + separador + (zerosEsquerda(dataAtual.getMonth()+1,2).toString()) + separador + zerosEsquerda(dataAtual.getDate().toString(),2));
	return dataAtualFormatada;
}