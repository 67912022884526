<template>
  <div>
    <!-- <b-button size="sm" variant="link" class="expanded" @click="toggleExpand" :title="expanded?'Voltar ao normal':'Expandir sistema'">
      <v-icon v-if="!expanded" scale="1" name="expand"/>
      <v-icon v-if="expanded" scale="1" name="compress"/>
    </b-button> -->
    <v-icon v-if="auth" variant="link" class="expanded" scale="1.8" name="bars" v-b-toggle.sidebar-backdrop title="Clique para abrir o menu"/>
    <span class="ambiente" v-if="ambiente">
      <div>
        {{ambiente}}
      </div>
    </span>
    <span class="ola">
      <div>
        Clic - CDI
      </div>
      <div class="perfilEmpresa" v-if="auth" @click="alert('erro')">
        Empresa: {{nomeEmpresa}}
      </div>
    </span>

    <b-sidebar
      id="sidebar-backdrop"
      title="Menu"
      header-class="menu_titulo"
      backdrop
      shadow
      footer
      no-header>
      <template #footer="{ hide }">
       <div class="d-flex bg-dark text-light justify-content-end px-3 py-2">
        <b-button size="sm" @click="hide" variant="danger"><v-icon name="arrow-left"/> Fechar</b-button>
       </div>
      </template>

      <nav id="sidebar">
        <div class="menu_titulo">
          <img alt="Vue logo" src="../../assets/logo-clic.png" class="menu_imagem">
          <div class="menu_texto">
            Clic Tecnologia
          </div>
        </div>
        <div id="menu">
          <ul class="components">
            <a :href="`/inventario`" v-if="menuPermitido('/inventario')"><li>Inventário</li></a>
            <a :href="`/escrituracao`" v-if="menuPermitido('/escrituracao')"><li>Escrituração</li></a>
            <a :href="`/lancamentos`" v-if="menuPermitido('/lancamentos')"><li>Lançamentos Contábeis</li></a>
            <a :href="`/escrituracao/divergencias`" v-if="menuPermitido('/escrituracao/divergencias')"><li>Conciliação de Notas</li></a>
            <a v-if="menuPermitido('/depara')"><li @click="troggleMenu('dePara')">
              <div class="labelSubMenu">
                <span>De-Para</span>
                <v-icon :name="opened.dePara ? 'angle-down':'angle-right'"></v-icon>
              </div>
            </li></a>
            <div v-if="opened.dePara && menuPermitido('/depara')" id="submenu">
              <ul>
                <a :href="`/depara/mensagem`"><li>Mensagem</li></a>
                <a :href="`/depara/ncm`"><li>NCM</li></a>
                <a :href="`/depara/produto`"><li>Produtos</li></a>
                <a :href="`/depara/contacontabil`"><li>Conta Contábil</li></a>
                <a :href="`/depara/itemsped`"><li>Item SPED</li></a>
                <a :href="`/depara/participantes`"><li>Participantes</li></a>
                <a :href="`/depara/seriefiscal`"><li>Série Fiscal</li></a>
              </ul>
            </div>
            <a :href="`/monitor`" v-if="menuPermitido('/monitor')"><li>Monitor</li></a>
            <a v-if="menuPermitido('/logs')"><li @click="troggleMenu('log')">
              <div class="labelSubMenu">
                <span>Logs</span>
                <v-icon :name="opened.log ? 'angle-down':'angle-right'"></v-icon>
              </div>
            </li></a>
            <div v-if="opened.log && menuPermitido('/logs')" id="submenu">
              <ul>
                <a :href="`/logs/inventarios`"><li>Inventário</li></a>
                <a :href="`/logs/notas`"><li>Escrituração</li></a>
                <a :href="`/logs/lancamentos`"><li>Lançamentos contábeis</li></a>
              </ul>
            </div>
            <!-- <a><li @click="troggleMenu('consulta_origem')">
              <div class="labelSubMenu">
                <span>Consultas no Oracle</span>
                <v-icon :name="opened.consulta_origem ? 'angle-down':'angle-right'"></v-icon>
              </div>
            </li></a>
            <div v-if="opened.consulta_origem" id="submenu">
              <ul>
                <a :href="`/consulta/origem/notas`"><li>Notas</li></a>
                <a :href="`/consulta/origem/produtos`"><li>Produtos</li></a>
              </ul>
            </div> -->
            <a v-if="master"><li @click="troggleMenu('config')">
              <div class="labelSubMenu">
                <span>Configurações</span>
                <v-icon :name="opened.config ? 'angle-down':'angle-right'"></v-icon>
              </div>
            </li></a>
            <div v-if="opened.config" id="submenu">
              <ul>
                <a :href="`/config/empresas`"><li>Empresas</li></a>
                <a :href="`/config/usuarios`"><li>Usuários</li></a>
              </ul>
            </div>
            <a><li @click="troggleMenu('empresas')">
              <div class="labelSubMenu">
                <span>Mudar de empresa</span>
                <v-icon :name="opened.empresas ? 'angle-down':'angle-right'"></v-icon>
              </div>
            </li></a>
            <div v-if="opened.empresas" id="submenu">
              <ul>
                <a v-for="item in empresas" :key="item.id" href="javascript:window.location.href=window.location.href" @click="registraKey(item.token)"><div class="empresa">{{item.idCliente}}. {{item.nome}}</div></a>
              </ul>
            </div>
            <a :href="`/`" @click="excluirKey()"><li>Efetuar logoff</li></a>
          </ul>
        </div>
      </nav>
    </b-sidebar>
    <!-- <b-dropdown
      size="sm"
      class="sino"
      variant="link"
      text-blue
      no-caret>
      <template slot="button-content">
        <v-icon scale="1.2" name="bell" class="sino"/>
      </template>
      
      <b-dropdown-item>
        Sem notificações
      </b-dropdown-item>     
    </b-dropdown> -->
    <!-- <b-dropdown
      class="usuarioMenu"
      variant="link"
      text-blue
      no-caret>
      <template slot="button-content">
        <BImg
          class="imagem"
          rounded="circle"/> -->
          <!-- :src="require('@/images/user.svg')"/> -->
          <!-- <b-avatar src="https://placekitten.com/300/300" size="md"></b-avatar>
          <v-icon class="barra" scale="1" name="bars"/>
      </template>

      <b-dropdown-item
        :to="{ name: 'rota-home'}">
        <v-icon scale="1" name="user-circle"/>
        Integração inventário
      </b-dropdown-item>
      <b-dropdown-item
        :to="{ name: 'rota-home'}">
        <v-icon scale="1" name="building"/>
        Integração escrituração nota
      </b-dropdown-item>
      <b-dropdown-item
        :to="{ name: 'rota-home'}">
        <v-icon scale="1" name="info-circle"/>
        Integração lançamentos contábeis
      </b-dropdown-item>
      <b-dropdown-item
        @click="abrirDocumentacao">
        <v-icon scale="1" name="book"/>
        Configurações
      </b-dropdown-item>      
    </b-dropdown> -->
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";
import { BDropdown } from 'bootstrap-vue'
import { BDropdownItem } from 'bootstrap-vue'
import { BListGroup, BPopover } from 'bootstrap-vue'
import { BImg } from 'bootstrap-vue'
// import { getUserObj } from '@/common/localStorage/localStorage'
import { BInputGroup } from "bootstrap-vue";
import { BInputGroupAppend } from "bootstrap-vue";
import { BFormInput } from "bootstrap-vue";
import { getUserObj,setUserObj,removeUserObj } from "@/common/localStorage/localStorage";

export default {
  components: {
    BButton,
    BDropdown,
    BDropdownItem,
    BListGroup,
    BPopover,
    BImg,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
  },
  data() {
    return {
      empresas: null,
      expanded: false,
      nomeEmpresa:null,
      auth:null,
      master: false,
      opened : {
        dePara: false,
        log: false,
        config: false,
        consulta_origem: false,
        empresas: false
      },
      ambiente: process.env.VUE_APP_AMBIENTE == "2" ? "Homologação" : ""
    }
  },
  async created() {
    this.empresas = (getUserObj()).usuario.empresas;
    this.nomeEmpresa = (getUserObj()).empresa.nome;
    this.master = (getUserObj()).usuario.master;
    // this.auth = this.$route.params.auth;
    this.auth = (getUserObj()).token;
  },
  methods: {
    troggleMenu(variavel) {
      Object.keys(this.opened).forEach((key) => {
        if (variavel === key) {
          this.opened[key] = !this.opened[key];
        } else {
          this.opened[key] = false;
        }
      });
    },
    getUser() {      
      // return getUserObj();
    },
    registraKey(token) {
      if (token) {
        let obj = {
          token,
          usuario: (getUserObj()).usuario
        };

        setUserObj(obj);
      }
    },
    excluirKey(token) {
      removeUserObj();
    },
    abrirDocumentacao() {
      window.open('http://manual.griffooficinas.com.br', "_blank")
    },
    menuPermitido(path) {
      let rota = this.$router.options.routes.filter(r => r.path == path)[0];
      let modulo = (getUserObj()).modulos.filter(m => m == rota.name || m == '*')[0];
      return modulo && modulo.length > 0;
    },
    toggleExpand() {
      //Expande a tela do sistema
      var elem = document.documentElement;
      if (
        document.fullscreenEnabled || 
        document.webkitFullscreenEnabled || 
        document.mozFullScreenEnabled ||
        document.msFullscreenEnabled
      ) {
        if(!this.expanded){
          if (elem.requestFullscreen) {
            elem.requestFullscreen();
            this.expanded = true;
            return;
          } else if (elem.webkitRequestFullscreen) {
            elem.webkitRequestFullscreen();
            this.expanded = true;
            return;
          } else if (elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen();
            this.expanded = true;
            return;
          } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
            this.expanded = true;
            return;
          }
        }else{
          if (document.exitFullscreen) {
            document.exitFullscreen();
            this.expanded = false;
            return;
          } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
            this.expanded = false;
            return;
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
            this.expanded = false;
            return;
          } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
            this.expanded = false;
            return;
          }
        }

      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "@/common/scss/colors.scss";
  @import "@/common/scss/breakpoints.scss";

  .imagem {
    width: 36px;
    height: 36px;
  }

  .ola {
    color: $cor-fonte-clara;
    font-weight: 400;
  }

  .perfilEmpresa {
    color: $cor-fonte-clara;
    font-size: 11px;
  }

  .barra {
    color: $cor-fonte-clara;
  }

  .alerta {
    fill: $cor-principal-escura;
  }

  .expanded {
    display: none;
    color: $cor-fonte-clara;
    left: 1rem;
    top: 1rem;
    position: absolute;
    display: inherit;
    // @media (min-width: $small-size) {
    //   left: 1rem;
    //   top: 1rem;
    //   position: absolute;
    //   display: inherit;
    // }
  }

  .expanded:hover {
    color: $cor-fundo-escura
  }

  .ambiente {
    display: none;
    color: $cor-fonte-clara;
    left: 40%;
    top: 0.5rem;
    padding: 10px;
    position: absolute;
    display: inherit;
    background: #FF0000;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 10;
  }

  .menu {
    &_titulo {
      z-index: 100;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      width: 100%;
      height: 3.9rem;
      background-color: $cor-principal;
    }

    &_texto {
      font-size: 1.8rem;
      color: $cor-fonte-clara;
    }

    &_imagem {
       height: 2.5rem
    }
  }

  .sino {
    color: $cor-principal-escura;
  }

  .labelSubMenu {
    display: flex;
    width: 100%;
    align-items: center; /* left and right */
    justify-content: space-between; /* up and down */
  }

  a, a:hover, a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
  }
  
  #menu {
    color: #000;
    transition: all 0.3s;
    font-size: 0.9rem;
    letter-spacing: 1px;
  }

  #menu>ul {
    padding: 10px 0;
    // border-bottom: 1px solid $cor-fundo-escura;
  }

  #menu ul p {
    color: #fff;
    padding: 6px;
  }

  #menu ul li {
    cursor: pointer;
    padding: 6px;
    font-size: 1.0em;
    display: block;
  }
  
  #menu > ul > a > li:hover {
    color: #000;
    background: $cor-fundo-escura;
    border-left: 6px solid $cor-principal;
  }

  #submenu ul {
    cursor: pointer;
    padding: 0 0 0 10px;
    // font-size: 1.1em;
    display: block;
  }

  #submenu ul li {
    cursor: pointer;
    padding: 5px;
    font-size: 0.8em;
    display: block;
  }
  
  #submenu > ul > a > li:hover {
    color: #000;
    background: $cor-fundo-escura;
    // border-left: 1px solid $cor-principal;
  }

</style>
