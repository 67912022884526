import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueSocketIO from 'vue-socket.io'
import SocketIOClient from 'socket.io-client'
import Loading from 'vue-loading-overlay'
import VeeValidate from 'vee-validate'

//Estilos da aplicação
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './common/scss/bootstrap-custom.scss'
import 'vue-awesome/icons'
import 'vue-loading-overlay/dist/vue-loading.css';

//Layouts utilizados na aplicação
import LayoutBasico from './components/layouts/LayoutBasico'
import LayoutNavBar from './components/layouts/LayoutNavBar'
import LayoutTelas from './components/layouts/LayoutTelas'

//Inicializar o BootstrapVue
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

//Icones
// import 'vue-awesome/icons/regular'
import Icon from 'vue-awesome/components/Icon/'

//Gerais
import veeConfig from './common/terceiros/veevalidate'
import Clipboard from 'v-clipboard'


//Components
Vue.component('LayoutBasico', LayoutBasico)
Vue.component('LayoutNavBar', LayoutNavBar)
Vue.component('LayoutTelas', LayoutTelas)
Vue.component('v-icon', Icon)
Vue.component('loading', Loading)

import { API_SOCKET } from './common/services/rotasServidor'
Vue.use(new VueSocketIO({
  debug: false,
  connection: SocketIOClient(API_SOCKET, { path: '/websocket'}),
}))

//Uses
Vue.use(BootstrapVue, IconsPlugin)
Vue.use(VeeValidate, veeConfig)
Vue.use(Clipboard)

//Config
Vue.config.productionTip = false;
Vue.config.disableHostCheck = true;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
