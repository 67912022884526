export const zerosEsquerda = (valor, tamanho) => {
  let retorno = String(valor);
	while (retorno.length < tamanho) {
    retorno = "0" + retorno;
  }
  return retorno;
}

export const retornaSomentePrimeiraMaiusculo = valor => 
  valor
    .replace(/(\B)[^ ]*/g,match => (match.toLowerCase()))
    .replace(/^[^ ]/g,match=> (match.toUpperCase()))
