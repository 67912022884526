import { verificaToken } from '@/common/services/usuarioService'
import { getUserObj, setUserObj, removeUserObj, adicionaAuthorization } from '@/common/localStorage/localStorage'
import { TOKEN_ADICIONAR, TOKEN_REMOVER, TOKEN_VALIDO } from './mutation-types'

const state = {
  userObj: getUserObj(),
  autenticado: getUserObj()?true:false,
}

const mutations = {
  [TOKEN_ADICIONAR]: (state, payload) => {
    state.userObj = payload
    state.autenticado = true
  },
  [TOKEN_REMOVER]: (state) => {
    state.userObj = '';
    state.autenticado = false
  },
  [TOKEN_VALIDO]: (state) => {
    state.autenticado = true
  },
}

const actions = {
  // realizaLogin: ({ commit }, objDados) => new Promise((resolve, reject) => {
  //   login(objDados)
  //     .then((res) => {
  //       const userObj = res.data
  //       commit(TOKEN_ADICIONAR, userObj)
  //       setUserObj(userObj);
  //       resolve(res)
  //     })
  //     .catch(err => reject(err))
  // }),
  verificaToken: ({ commit }, token) => new Promise((resolve, reject) => {
    verificaToken(token)
      .then((res) => {
        commit(TOKEN_VALIDO)
        adicionaAuthorization(token);
        resolve(res)
      })
      .catch((err) => {
        commit(TOKEN_REMOVER);
        removeUserObj();
        reject(err);
      })
  }),
  sairDoSistema: ({ commit }) => {
    commit(TOKEN_REMOVER)
    removeUserObj();
  },
}

const getters = {
  userObj: state => state.userObj,
  autenticado: state => state.autenticado,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
