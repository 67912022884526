<template>
  <div id="app">
    <component :is="layout">
      <router-view/>
    </component>
  </div>
</template>

<script>

export default {
  computed: {
    // Método que retorna o layout que a página deve possuir
    // Verificando se possui na configuração de rotas um objeto meta com a propriedade layout
    // O padrão é LayoutBasico.
    layout() {      
      return this.$route.meta.layout || 'LayoutBasico';
    },
  }
}
</script>

<style lang="scss">
  @import '@/common/scss/colors.scss';
html,
body {
  background-color: $cor-fundo;
  height: 100vh;
  width: 100%;
  margin: 0vh;
}

#app {
  display: flex;
  
  // display: flex;
  // flex-direction: column;
  min-height: 100vh;
  width: 100%;
  // background-color: $cor-fundo;
}

</style>
