<template>
  <div class="layout">
    <Navbar class="layout__topo"/>
    <main class="layout__corpo">
      <!-- <PropagandaTop class="layout__propaganda"/> -->
      <slot/>
    </main>
    <!-- <aside class="layout__lateral" :class="{ 'layout__lateral__ativada' : barraHabilitada }">
      <div class="clickbtn" @click="controleBarraLateralMethod">
        <v-icon v-if="!barraHabilitada" scale="3" name="angle-right" class="clickbtn__icon"/>
        <v-icon v-if="barraHabilitada" scale="3" name="angle-left" class="clickbtn__icon"/>
      </div>
      <BarraLateral @clicouItemMenu="clicouItemMenuMethod"/>
    </aside> -->
  </div>
</template>

<script>
// import BarraLateral from "../barra-lateral/BarraLateral.vue";
import Navbar from "@/components/navbar/Navbar.vue";
// import PropagandaTop from "@/components/propaganda/PropagandaTop.vue";

export default {
  name: "LayoutNavBar",
  data() {
    return {
      barraHabilitada: true,
      windowWidth: window.innerWidth
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", () => {
        this.windowWidth = window.innerWidth;
      });
    });
  },
  watch: {
    windowWidth(newWidth) {
      if (!this.ehVersaoMobile(newWidth)) {
        this.barraHabilitada = true;
      }
    }
  },
  components: {
    // BarraLateral,
    Navbar,
    // PropagandaTop
  },
  methods: {
    controleBarraLateralMethod() {
      this.barraHabilitada = !this.barraHabilitada;
    },
    clicouItemMenuMethod() {
      if (this.ehVersaoMobile(this.windowWidth)) {
        this.barraHabilitada = false;
      }
    },
    ehVersaoMobile(tamanho) {
      return tamanho <= 1200;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/common/scss/breakpoints.scss";
@import "@/common/scss/colors.scss";

.layout {
  height: inherit;
  min-width: 100%;
// #1a1a1a
  display: flex;
  flex-direction: column;
  background: $cor-fundo-escura;

  @media (min-width: $medium-size) {
    flex-direction: row;
  }

  &__topo {
    position: fixed;
  }

  // &__lateral {
  //   position: fixed;
  //   top: 0;
  //   left: -16rem;
  //   width: 16rem;
  //   height: 100%;
  //   transition: 0.3s;
  //   z-index: 101;
  //   background-color: $cor-barralateral;
  //   box-shadow: -1px 2px 12px $cor-fonte-escura;

  //   @media (min-width: $extra-large-size) {
  //     left: -15rem;
  //     width: 15rem;
  //   }

  //   &__ativada {
  //     left: 0;
  //   }
  // }

  &__corpo {
    order: 3;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.5em;

    @media (min-width: $medium-size) {
      display: flex;
      align-items: center;
    }

    // @media (min-width: $extra-large-size) {
    //   margin-left: 15em;
    // }
  }
  
  &__propaganda {
    // position: afixed;
    margin-top: 4rem;
    height: 5rem;
    margin-bottom: -1rem;
  }
}

.clickbtn {
  &__icon {
    display: none;
  }
  @media (max-width: $extra-large-size) {
    position: absolute;
    width: 50px;
    background-color: #f57c00;
    height: 50px;
    top: 6px;
    right: -50px;
    border: none;
    cursor: pointer;
    outline: none;
    border-top-right-radius: 20%;
    border-bottom-right-radius: 20%;
    content: "";

    &__icon {
      margin-left: 0.25em;
      color: $cor-fonte-clara;
      display: inline;
    }
  }
}
</style>
