<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <p>
      Você está sem acesso ao CDI, favor entrar em contato com a 
      equipe responsável da
      <a href="https://cdi.clictecnologia.com.br/suporte" target="_blank" rel="noopener"> Clic Tecnologia</a>.
    </p>
    <h6><a href="https://www.clictecnologia.com.br" target="_blank" rel="noopener"> www.clictecnologia.com.br</a></h6>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/common/scss/colors.scss";

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: $cor-principal;
}
</style>
