import axios from 'axios'

const USERKEY = '_cdi'

const temKey = () => localStorage.getItem(USERKEY) !== null

export const adicionaAuthorization = (token) => {
  axios.defaults.headers.common.Authorization = token
  axios.defaults.headers.common['Content-Type'] = 'application/json'
}

export const getUserObj = () => {
  if(temKey()){
    let userObj = JSON.parse(localStorage.getItem(USERKEY));
    adicionaAuthorization(userObj.token)
  }
  return temKey() ? JSON.parse(localStorage.getItem(USERKEY)) : ''
}

export const setUserObj = (userObj) => {
  localStorage.setItem(USERKEY, JSON.stringify(userObj))
  adicionaAuthorization(userObj.token)
}

export const removeUserObj = () => {
  if (temKey()) {
    localStorage.removeItem(USERKEY)
  }

  delete axios.defaults.headers.common.Authorization;
}
