import Vue from 'vue'
import Vuex from 'vuex'

import autenticacao from './modules/autenticacao'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    autenticacao
  }
})
