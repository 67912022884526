import br from 'vee-validate/dist/locale/pt_BR'
import { Validator } from 'vee-validate'
import * as DataHelper from '../helpers/DataHelper'

Validator.localize('pt_BR', br)
Validator.extend('minLenNum', {
  getMessage: (field, length) => `O campo ${field} deve possuí no mínimo ${length} dígitos`,
  validate: (value, length) => {
    if (value === undefined || value === null)
      return false

    return value.replace(/\D/g, '').length >= length
  }
})

Validator.extend('minLenTel', {
  getMessage: field => `O campo ${field} deve possuí no mínimo 8 dígitos`,
  validate: value => {
    if (value === undefined || value === null)
      return false

    return value.replace(/\D/g, '').length >= 12
  }
})

Validator.extend('onlyNumberLetter', {
  getMessage: field => `O campo ${field} não deve conter caracteres especiais`,
  validate: value => {
    if (value === undefined || value === null)
      return false

    return value.search(/[^a-z0-9]+/) === -1
  }
})

Validator.extend('dataIgualMaior', {
  getMessage: field => `O campo ${field} deve ser maior`,
  validate: (value, args) => {
    if (value === undefined || value === null)
      return false
    
    return DataHelper.dataEhMaiorIgual(value, args[0])
  }
})

const veeConfig = {
  aria: true,
  classNames: {},
  classes: false,
  delay: 0,
  errorBagName: 'errors',
  events: 'input',
  fieldsBagName: 'veeFields',
  i18n: null,
  i18nRootKey: 'validations',
  inject: true,
  locale: 'pt_BR',
  dictionary: {},
  strict: true,
  validity: false,
}

export { veeConfig as default }
