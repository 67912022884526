import axios from 'axios'
import { API_AUTENTICACAO } from './rotasServidor'

const verificaToken = token => axios.get(`${API_AUTENTICACAO}/token/validar/${token}`, { headers: { Authorization: token } })
const autenticaUsuario = (login,senha) => axios.post(`${API_AUTENTICACAO}/token/validar/usuario`, { login: login, senha: senha })
const autenticaUsuarioToken = (idUser,token) => axios.post(`${API_AUTENTICACAO}/token/validar/usuario/token`, { idUser, token })

export {
  verificaToken,
  autenticaUsuario,
  autenticaUsuarioToken
}
